/*
.navbar {
    background-color: #ffffff !important;
} */

.navbar-nav .ms-auto {
    margin-left: auto;
}

.navbar-nav .nav-link {
margin-left: 10px; /* 可选：添加一些左侧间距 */
}

.navbar-brand,
.navbar-nav .nav-link {
    color: #ffffff !important; /* 设置文本颜色 */
}

.bi-list {
    border: none;
    color: #ffffff;
    font-size: 2rem;
}

.navbar-brand {
    position: relative;;
    left: 20px;
    font-family: 'Alkatra', sans-serif;
    font-size: 3rem !important;
}  